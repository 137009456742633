import { useState } from "react";
import { useQueryString } from "../hooks/useQueryString";
import Title from "../components/title/Title";
import CardList from "../components/cardList/CardList";
import { TFilteredData } from "../types/queryData.type";
import Life from "../components/banner/Life";
import { ToLang } from "../utils/queryToObjective";
import Layout from "../components/layout/Layout";
import Win from "../components/modal/win/Win";
import { FullHeight } from "../styles/GlobalStyle";

const ResultPage = () => {
  const { queryData } = useQueryString();

  const {
    hr,
    hr_status,
    resp,
    resp_status,
    rmssd,
    rmssd_status,
    fatigue,
    fatigue_status,
    stress,
    stress_status,
    bp_sys,
    bp_dia,
    bp_status,
    temp,
    temp_status,
    alcohol,
    alcoholContent,
    score,
    win,
    age,
  } = queryData;
  const [isShow, setIsShow] = useState(win);
  const filteredData: TFilteredData[] = resp
    ? [
        { key: "alcohol", value: alcohol, sideValue: alcoholContent },
        { key: "hr", value: hr, status: hr_status },
        { key: "resp", value: resp, status: resp_status },
        { key: "fatigue", value: fatigue, status: fatigue_status },
        { key: "stress", value: stress, status: stress_status },
        { key: "bp_sys", value: `${bp_sys}_${bp_dia}`, status: bp_status },
        { key: "temp", value: temp, status: temp_status },
      ].filter((f) => !f.value.includes("null"))
    : [
        { key: "alcohol", value: alcohol, sideValue: alcoholContent },
        { key: "hr", value: hr, status: hr_status },
        {
          key: "bp_sys",
          value: age ? `${bp_sys}_${bp_dia}_${age}` : `${bp_sys}_${bp_dia}`,
          status: bp_status,
        },
        { key: "fatigue", value: fatigue, status: fatigue_status },
        { key: "stress", value: stress, status: stress_status },
        { key: "rmssd", value: rmssd, status: rmssd_status },
        { key: "temp", value: temp, status: temp_status },
      ].filter((f) => !f.value.includes("null"));

  const isBadGradeCondition =
    temp_status === "FEVER" || temp_status === "HYPOTHERMIA";

  console.log(ToLang().titleByScoreWithResp[3]);

  const titleBalance = resp
    ? // 알코올 검출시 알코올 멘트
      alcohol === "true"
      ? ToLang().titleByScoreWithResp[6]
      : // 체온 높거나, 낮을시 멘트
      isBadGradeCondition
      ? ToLang().titleByScoreWithResp[5]
      : // 등급에 따라 멘트
        ToLang().titleByScoreWithResp[ToLang().calcByScore(score)]
    : ToLang().titleByScore;

  return (
    <FullHeight>
      <Layout>
        <>
          <Title title={titleBalance} />
          <CardList queryData={filteredData} />
        </>
      </Layout>
      <Life />
      {String(isShow) === "true" && <Win closeModal={() => setIsShow(false)} />}
    </FullHeight>
  );
};

export default ResultPage;
