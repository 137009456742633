import { pxToVh } from "../utils/pxToVh";

const palette = {
  black: "#000",
  white: "#fff",
  message: {
    300: "#98A2B2",
    400: "#5C6574",
    500: "#C4CCD7",
  },
  danger: {
    200: "#0097FC",
    300: "#00CDDD",
    400: "#FFB400",
    primary: "#FF4444",
    rowTemp: "#98A2B2",
  },
};

const fontSize = {
  28: pxToVh(28),
  large: pxToVh(22),
  20: pxToVh(20),
  18: pxToVh(18),
  16: pxToVh(16),
  ladium: pxToVh(14),
  medium: pxToVh(12),
  small: pxToVh(8),
};

const fontWeight = {
  bold: 700,
  semiBold: 500,
  regular: 400,
};

export const theme = { palette, fontSize, fontWeight };
