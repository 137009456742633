export const ja = {
  title_1_index_0: "おめでとうございます！健康な状態ですね！",
  title_1_index_1: "継続的な運動とバランスの取れた食事を続けてください！",
  title_2_index_0: "健康状態は正常です。",
  title_2_index_1: "健康な習慣を維持し、定期的に健康をチェックしてください。",
  title_3_index_0: "健康に関心を持つ必要があります。",
  title_3_index_1: "生活習慣を点検し、専門家の相談を検討してください。",
  title_4_index_0: "健康状態に注意が必要です。",
  title_4_index_1: "生活習慣の改善と専門家との相談をお勧めします。",
  title_5_index_0: "あら！健康状態が危険です。",
  title_5_index_1: "専門家の助言を受けて、健康管理を行う必要があります。",
  title_6_index_1:
    "アルコールが体から検出されました。健康管理にもっと力を入れてください！",

  title_index_0: "けんこうじょうたい しんだんけっか",
  title_index_1: "生活習慣を確認し、必要に応じて担当者に相談してください。",

  temp_healthy: "正常",
  temp_fever: "発熱",
  temp_hypothermia: "低体温",

  status_healthy: "健康",
  status_normal: "正常",
  status_attention: "関心",
  status_warning: "警告",
  status_danger: "危険",

  query_info_hr_title: "心拍数",
  query_info_hr_unit: "回",
  query_info_hr_subuUnit: " / 分",
  query_info_hr_standard: "標準範囲(60~100回)",

  query_info_rmssd_title: "ストレス耐性",
  query_info_rmssd_unit: "",
  query_info_rmssd_subuUnit: "",
  query_info_rmssd_standard: "標準範囲(40以上)",

  query_info_rep_title: "呼吸数",
  query_info_rep_unit: "回",
  query_info_rep_subuUnit: " / 分",
  query_info_rep_standard: "標準範囲(6~18回)",

  query_info_fatigue_title: "疲労度",
  query_info_fatigue_standard: "標準範囲(32以下)",

  query_info_stress_title: "ストレス",
  query_info_stress_standard: "標準範囲(49以下)",

  query_info_bp_title: "血圧",

  query_info_bp_standard30: "30代以下 標準範囲(119/79)",
  query_info_bp_standard4050: "40～50代 標準範囲(129/84)",
  query_info_bp_standard60: "60代以上 標準範囲(139/89)",

  query_info_temp_title: "体温",
  query_info_temp_standard: "標準範囲(36~37)",
  query_info_alcohol_title: "アルコール",

  alcohol_status_true: "陽性",
  alcohol_status_false: "陰性",
  blood_status_row: "低い",
  blood_status_middle: "平均",
  blood_status_high: "高い",

  // blood
  gener_mail: "男性",
  gener_femail: "女性",
  month: "月",
  day: "日",
  measureStandard: "測定基準",
  ageLabel: "(満)",
  age: "歳",
  BloodVesselHealthCardUserAge: "使用者年齢",
  BloodVesselHealthCardBloodVesselAge: "血管の年齢",
  BloodVesselHealthCardCompareUserAge: "使用者年齢と比較",
  BloodVesselPulseAverageCardPpgStateTitle: "脈拍の状態",
  BloodVesselPulseAverageCardAgePpgAvgTitle: "年齢別平均",
  BloodVesselPulseAverageCardAgeRangePpgAvgTitle: "年齢層別平均",
  BloodVesselResultSummaryTitle: "結果の概要",
  BloodVesselResultSummaryHealthTypeTitle:
    "血管の健康度タイプは3タイプ(正常、注意、管理)で、\n血管の健康指数は低いほど良いです。",
  BloodVesselResultSummaryHealthType: "血管の健康度タイプ",
  BloodVesselResultSummaryHealthIndex: "血管の健康指数",
  BloodVesselResultSummaryHealthTypeDialogButton:
    "血管の健康度タイプとは何ですか？",
  BloodVesselResultSummaryHealthIndexChartDialogButton:
    "血管の健康指数の波形はどのように見るのですか？",
  BloodVesselHealthCardTitle: "血管の健康",
  BloodVesselPulseAverageCardUserPpgResultTitle: "使用者の数値",
  BloodVesselPulseAverageCardPpgAvg: "平均脈拍",
  BloodVesselResultDetailTitle: "測定の詳細結果",
  BloodType: "タイプ",
  BloodVesselHealthIndexChartTypeDialogTitle:
    "血管の健康指数の波形は\n血管の弾力性を示します。",
  BloodVesselHealthIndexChartTypeDialogSubTitle: "4つの段階に分かれています。",
  BloodVesselHealthTypeDialogTitle:
    "血管のタイプは\n血管の内部構造を示します。",
  BloodVesselHealthTypeDialogSubTitle: "大きく3つのタイプに分類されています。",
  BloodVesselHealthAgeDialogTitle: "血管の健康は\n血管の機能と状態を示します。",
  BloodVesselHealthAgeDialogSubTitle: "分かりやすく説明します！",
  BloodVessleStandard: "標準",
  BloodVessleWarning: "注意",
  BloodVessleDanger: "診断が必要",
  BloodVesslPulseValue: "脈拍数です",
  BloodVesselResultHealthTitle: "血管の状態が良好です。",
  BloodVesselResultWaringTitle: "血管の健康に注意が必要です。",
  BloodVesselResultDangerTitle: "専門家との相談が必要です。",
  BloodVesselResultSummaryHealth:
    "血管タイプが「標準」で、20〜30代の波形を示しており、良好です。適切な生活習慣と定期的な運動で現在の状態を維持してください。",
  BloodVesselResultSummaryWaring:
    "血管タイプが「注意」で、40〜50代の波形を示しており、注意が必要です。継続的な管理と生活習慣の改善を検討してください。",
  BloodVesselResultSummaryDanger:
    "血管タイプが「診断が必要」で、60〜70代の波形を示しており、管理が必要です。専門家との相談と生活習慣の改善が急務です。",

  // better
  BetterTitle: "これでより良くなります",
  food: "食事",
  exercise: "運動",
  better_title_food: "マグネシウムとオメガ3、硫酸化物質が\n含まれた献立",
  better_title_exercise: "持続的な有酸素運動と柔軟性運動",
  better_content_food:
    "飽和脂肪、トランス脂肪、ナトリウムの摂取を減らし、植物性の多様な食品（果物、野菜、全粒穀物、ナッツ）を摂取してください。また、過度なアルコール摂取は血圧を上げる可能性がありますので、適度な量でアルコールを摂取してください。",
  better_content_exercise:
    "血管の健康のために、中程度から低強度の有酸素運動（散歩、サイクリング）を週3〜5回、各30分間行ってください。また、ストレッチやヨガを行い、柔軟性を高め血液循環を改善してください。",
};
