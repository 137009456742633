import React from "react";
import {
  BodyContainer,
  StandardValue,
  ValueSub,
  ValueTitle,
} from "./CardBodyStyles";
import { pxToVh } from "../../../utils/pxToVh";
import { ToLang } from "../../../utils/queryToObjective";

const CardBody = ({
  value,
  subUnit = [],
  unit = "",
  standard,
  subUnitSize,
  subUnitColor,
}: {
  value: string | string[];
  subUnit?: string | string[];
  unit?: string;
  standard?: string;
  subUnitSize?: string;
  subUnitColor?: string;
}) => {
  let checkBpValue =
    typeof value === "object" ? (
      <div>
        <ValueTitle style={{ marginRight: pxToVh(10) }}>
          {value[0]}
          <ValueSub $subUnitSize={subUnitSize} $subUnitColor={subUnitColor}>
            {subUnit[0]}
          </ValueSub>
        </ValueTitle>
        <ValueTitle>
          {value[1]}
          <ValueSub $subUnitSize={subUnitSize} $subUnitColor={subUnitColor}>
            {subUnit[1]}
          </ValueSub>
        </ValueTitle>
      </div>
    ) : (
      <ValueTitle>
        {`${value}${unit}`}
        <ValueSub $subUnitSize={subUnitSize} $subUnitColor={subUnitColor}>
          {subUnit}
        </ValueSub>
      </ValueTitle>
    );
  const statndardRange =
    typeof value === "object"
      ? ToLang().bpStandardScore(value[2] ? Number(value[2]) : 0)
      : standard;

  return (
    <BodyContainer>
      {checkBpValue}
      <StandardValue>{statndardRange}</StandardValue>
    </BodyContainer>
  );
};

export default CardBody;
