import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as Pages from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:lang/doyeon/:query" element={<Pages.ResultPage />} />
        <Route path="/:lang/exhibition/:query" element={<Pages.ResultPage />} />
        <Route
          path="/:lang/doyeon/blood/:query"
          element={<Pages.BloodPage />}
        />
        {/* lang 업데이트 전 배포되었던 기기들 호환 Adapter */}
        <Route path="/doyeon/:query" element={<Pages.ResultPage />} />
        <Route path="/doyeon/blood/:query" element={<Pages.BloodPage />} />
        <Route
          path="*"
          element={
            // <Navigate to="/ko/doyeon/q?hr=56&hr_status=ATTENTION_NONE&rmssd=4&rmssd_status=DANGER_HIGH&fatigue=24&fatigue_status=WARNING&stress=63&stress_status=NORMAL&bp_sys=32&bp_dia=64&bp_status=DANGER&score=73&alcohol=null&temp=74&temp_status=HYPOTHERMIA&age=55" />
            <Navigate to="/ko/doyeon/q?hr=56&hr_status=HEALTHY_NONE&resp=4&resp_status=DANGER_HIGH&fatigue=24&fatigue_status=WARNING&stress=63&stress_status=NORMAL&bp_sys=32&bp_dia=64&bp_status=DANGER&score=73&alcohol=null&temp=36&temp_status=HEALTHY&score=90" />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
