export interface IQueryData {
  hr: string;
  hr_status: string;
  resp: string;
  resp_status: string;
  rmssd: string;
  rmssd_status: string;
  fatigue: string;
  fatigue_status: string;
  stress: string;
  stress_status: string;
  bp_sys: string;
  bp_dia: string;
  bp_status: string;
  temp: string;
  temp_status: string;
  alcohol: string;
  alcoholContent: string;
  score: number;
  win: boolean;
  age: string;
}

export const defaultQueryData: IQueryData = {
  hr: "",
  hr_status: "",
  resp: "",
  resp_status: "",
  rmssd: "",
  rmssd_status: "",
  fatigue: "",
  fatigue_status: "",
  stress: "",
  stress_status: "",
  bp_sys: "",
  bp_dia: "",
  bp_status: "",
  temp: "",
  temp_status: "",
  alcohol: "",
  alcoholContent: "",
  score: 0,
  win: false,
  age: "",
};

export type TFilteredData = {
  key: string;
  value: string;
  status?: string;
  standard?: string;
  sideValue?: string;
};

export const queryItem: string[] = [
  "hr",
  "hr_status",
  "resp",
  "resp_status",
  "rmssd",
  "rmssd_status",
  "fatigue",
  "fatigue_status",
  "stress",
  "stress_status",
  "bp_sys",
  "bp_dia",
  "bp_status",
  "temp",
  "temp_status",
  "alcohol",
  "alcoholContent",
  "score",
  "win",
  "age",
];
