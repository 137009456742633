export const ko = {
  title_1_index_0: "축하합니다! 건강한 상태네요!",
  title_1_index_1: "꾸준한 운동과 균형 잡힌 식단을 계속 유지해주세요!",
  title_2_index_0: "건강 상태가 정상입니다.",
  title_2_index_1: "건강 습관을 유지하고, 정기적으로 건강을 체크하세요.",
  title_3_index_0: "건강에 대한 관심이 필요합니다.",
  title_3_index_1: "생활 습관을 점검하고, 전문가의 상담을 고려해보세요.",
  title_4_index_0: "건강 상태에 주의가 필요합니다.",
  title_4_index_1: "생활 습관 개선과 함께 전문가와의 상담을 추천해요.",
  title_5_index_0: "앗! 건강 상태가 위험해요.",
  title_5_index_1: "전문가의 도움을 받고, 건강 관리 조치를 취해야 해요.",
  title_6_index_1:
    "알코올이 몸에서 검출되었어요. 건강 관리에 좀 더 힘써주세요!",

  title_index_0: "건강 상태 진단 결과입니다.",
  title_index_1: "생활 습관을 점검한 후, 필요 시 담당자와 상담해 보세요.",

  temp_healthy: "정상",
  temp_fever: "발열",
  temp_hypothermia: "저체온",

  status_healthy: "건강",
  status_normal: "정상",
  status_attention: "관심",
  status_warning: "경고",
  status_danger: "위험",

  query_info_hr_title: "심박수",
  query_info_hr_unit: "회",
  query_info_hr_subuUnit: " / 분",
  query_info_hr_standard: "표준 범위(60~100회)",

  query_info_rmssd_title: "스트레스 저항도",
  query_info_rmssd_unit: "",
  query_info_rmssd_subuUnit: "",
  query_info_rmssd_standard: "표준 범위(40이상)",

  query_info_rep_title: "호흡수",
  query_info_rep_unit: "회",
  query_info_rep_subuUnit: " / 분",
  query_info_rep_standard: "표준 범위(6~18회)",

  query_info_fatigue_title: "피로도",
  query_info_fatigue_standard: "표준 범위(32이하)",

  query_info_stress_title: "스트레스",
  query_info_stress_standard: "표준 범위(49이하)",

  query_info_bp_title: "혈압",

  query_info_bp_standard30: "30대 이하 표준 범위(119/79)",
  query_info_bp_standard4050: "40~50대 표준 범위(129/84)",
  query_info_bp_standard60: "60대 이상 표준 범위(139/89)",

  query_info_temp_title: "체온",
  query_info_temp_standard: "표준 범위(36~37)",
  query_info_alcohol_title: "알코올",

  alcohol_status_true: "양성",
  alcohol_status_false: "음성",
  blood_status_row: "낮음",
  blood_status_middle: "평균",
  blood_status_high: "높음",

  // blood
  gener_mail: "남성",
  gener_femail: "여성",
  month: "월",
  day: "일",
  measureStandard: "측정 기준",
  ageLabel: "(만)",
  age: "세",
  BloodVesselHealthCardUserAge: "사용자 나이",
  BloodVesselHealthCardBloodVesselAge: "혈관 나이",
  BloodVesselHealthCardCompareUserAge: "사용자 나이 대비",
  BloodVesselPulseAverageCardPpgStateTitle: "맥박 상태",
  BloodVesselPulseAverageCardAgePpgAvgTitle: "연령 평균",
  BloodVesselPulseAverageCardAgeRangePpgAvgTitle: "연령대 평균",
  BloodVesselResultSummaryTitle: "결과 요약",
  BloodVesselResultSummaryHealthTypeTitle:
    "혈관 건강도 타입은 3타입(정상, 주의, 관리)이며,\n혈관 건강 지수는 낮을수록 좋습니다.",
  BloodVesselResultSummaryHealthType: "혈관 건강도 타입",
  BloodVesselResultSummaryHealthIndex: "혈관 건강 지수",
  BloodVesselResultSummaryHealthTypeDialogButton:
    "혈관 건강도 타입은 무엇인가요?",
  BloodVesselResultSummaryHealthIndexChartDialogButton:
    "혈관 건강 지수의 파형은 어떻게 보는건가요?",
  BloodVesselHealthCardTitle: "혈관 건강도",
  BloodVesselPulseAverageCardUserPpgResultTitle: "사용자 수치",
  BloodVesselPulseAverageCardPpgAvg: "평균 맥박",
  BloodVesselResultDetailTitle: "측정 상세결과",
  BloodType: "타입",
  BloodVesselHealthIndexChartTypeDialogTitle:
    "혈관 건강지수의 파형은\n혈관의 탄성도를 말해요.",
  BloodVesselHealthIndexChartTypeDialogSubTitle: "총 4단계로 구성되어 있어요.",
  BloodVesselHealthTypeDialogTitle:
    "혈관 유형은\n혈관 내부 구조 유형을 말해요.",
  BloodVesselHealthTypeDialogSubTitle: "크게 3가지 유형으로 구성되어있어요.",
  BloodVesselHealthAgeDialogTitle:
    "혈관 건강도는\n혈관의 기능과 상태를 말해요.",
  BloodVesselHealthAgeDialogSubTitle: "알기 쉽게 설명해드릴게요!",
  BloodVessleStandard: "표준",
  BloodVessleWarning: "주의",
  BloodVessleDanger: "진단 필요",
  BloodVesslPulseValue: "맥박 수치",
  BloodVesselResultHealthTitle: "혈관 상태가 양호해요.",
  BloodVesselResultWaringTitle: "혈관 건강 주의가 필요해요.",
  BloodVesselResultDangerTitle: "전문가와의 상담이 필요해요.",
  BloodVesselResultSummaryHealth:
    "혈관 타입이 ‘표준’(으)로, 2–30대의 파형을 띄고 있어 양호합니다. 올바른 생활 습관과 규칙적인 운동으로 현재의 상태를 유지하세요.",
  BloodVesselResultSummaryWaring:
    "혈관 타입이 ‘주의’(으)로, 4–50대의 파형을 띄고 있어 주의가 필요합니다. 꾸준한 관리와 생활습관 개선을 고려해보세요.",
  BloodVesselResultSummaryDanger:
    "혈관 타입이 ‘진단 필요’(으)로, 6–70대의 파형을 띄고 있어 관리가 필요합니다. 전문가와의 상담과 생활습관 개선이 시급합니다.",

  // better
  BetterTitle: "이렇게 하면 더 좋아요",
  food: "음식",
  exercise: "운동",
  better_title_food: "마그네슘과 오메가 3, 항산화 물질이 포함된 식단",
  better_title_exercise: "꾸준한 유산소 및 유연성 운동",
  better_content_food:
    "포화지방, 트랜스 지방, 나트륨 섭취를 줄이고, 식물 기반의 다양한 음식(과일, 채소, 전곡, 견과류)을 섭취하세요. 또한 과도한 알코올 섭취는 혈압을 높일 수 있습니다. 적당한 양으로 알코올을 섭취하세요.",
  better_content_exercise:
    "혈관 건강을 위해 중·저강도의 유산소 운동(걷기, 자전거 타기)을 주 3~5회, 각 30분씩 꾸준히 실시하세요. 또한, 스트레칭이나 요가로 유연성을 높여 혈액 순환도 개선해보세요.",
};
