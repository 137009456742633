export const en = {
  title_1_index_0: "축하합니다! 건강한 상태네요!",
  title_1_index_1: "꾸준한 운동과 균형 잡힌 식단을 계속 유지해주세요!",
  title_2_index_0: "건강 상태가 정상입니다.",
  title_2_index_1: "건강 습관을 유지하고, 정기적으로 건강을 체크하세요.",
  title_3_index_0: "건강에 대한 관심이 필요합니다.",
  title_3_index_1: "생활 습관을 점검하고, 전문가의 상담을 고려해보세요.",
  title_4_index_0: "건강 상태에 주의가 필요합니다.",
  title_4_index_1: "생활 습관 개선과 함께 전문가와의 상담을 추천해요.",
  title_5_index_0: "앗! 건강 상태가 위험해요.",
  title_5_index_1: "전문가의 도움을 받고, 건강 관리 조치를 취해야 해요.",
  title_6_index_1:
    "알코올이 몸에서 검출되었어요. 건강 관리에 좀 더 힘써주세요!",

  title_index_0: "Health Status Diagnosis Result",
  title_index_1:
    "Check your lifestyle habits and consult with a specialist if necessary.",

  temp_healthy: "정상",
  temp_fever: "발열",
  temp_hypothermia: "저체온",

  status_healthy: "건강",
  status_normal: "정상",
  status_attention: "관심",
  status_warning: "경고",
  status_danger: "위험",

  query_info_hr_title: "심박수",
  query_info_hr_unit: "회",
  query_info_hr_subuUnit: " / 분",
  query_info_hr_standard: "표준 범위(60~100회)",

  query_info_rmssd_title: "스트레스 저항도",
  query_info_rmssd_unit: "",
  query_info_rmssd_subuUnit: "",
  query_info_rmssd_standard: "표준 범위(40이상)",

  query_info_rep_title: "호흡수",
  query_info_rep_unit: "회",
  query_info_rep_subuUnit: " / 분",
  query_info_rep_standard: "표준 범위(6~18회)",

  query_info_fatigue_title: "피로도",
  query_info_fatigue_standard: "표준 범위(32이하)",

  query_info_stress_title: "스트레스",
  query_info_stress_standard: "표준 범위(49이하)",

  query_info_bp_title: "혈압",

  query_info_bp_standard30: "30대 이하 표준 범위(119/79)",
  query_info_bp_standard4050: "40~50대 표준 범위(129/84)",
  query_info_bp_standard60: "60대 이상 표준 범위(139/89)",

  query_info_temp_title: "체온",
  query_info_temp_standard: "표준 범위(36~37)",
  query_info_alcohol_title: "알코올",

  alcohol_status_true: "양성",
  alcohol_status_false: "음성",
  blood_status_row: "낮음",
  blood_status_middle: "평균",
  blood_status_high: "높음",

  // blood
  gener_mail: "Male",
  gener_femail: "Female",
  month: "M",
  day: "d,",
  measureStandard: "metrics",
  ageLabel: "",
  age: "age",
  BloodVesselHealthCardUserAge: "User Age",
  BloodVesselHealthCardBloodVesselAge: "Vascular Age",
  BloodVesselHealthCardCompareUserAge: "Compared to User's Age",
  BloodVesselPulseAverageCardPpgStateTitle: "Pulse State",
  BloodVesselPulseAverageCardAgePpgAvgTitle: "Age Group Average",
  BloodVesselPulseAverageCardAgeRangePpgAvgTitle: "Age Range \nAverage",
  BloodVesselResultSummaryTitle: "Summary of results",
  BloodVesselResultSummaryHealthTypeTitle:
    "The vascular health type is 3 types (normal, careful, and managed), and the lower the vascular health index, the better.",
  BloodVesselResultSummaryHealthType: "Vascular\nhealth type",
  BloodVesselResultSummaryHealthIndex: "Vascular\nHealth Index",
  BloodVesselResultSummaryHealthTypeDialogButton:
    "What is the type of vascular health?",
  BloodVesselResultSummaryHealthIndexChartDialogButton:
    "How do I see the waveform of the vascular\nhealth index?",
  BloodVesselHealthCardTitle: "Vascular Health",
  BloodVesselPulseAverageCardUserPpgResultTitle: "User's Figure",
  BloodVesselPulseAverageCardPpgAvg: "Average Pulse",
  BloodVesselResultDetailTitle: "Detailed measurement results",
  BloodType: "Type",
  BloodVesselHealthIndexChartTypeDialogTitle:
    "The waveform of the Vascular Health Index\nindicates the elasticity of your blood vessels.",
  BloodVesselHealthIndexChartTypeDialogSubTitle:
    "It is divided into four stages.",
  BloodVesselHealthTypeDialogTitle:
    "Vascular Type\nindicates the internal structure of your blood vessels.",
  BloodVesselHealthTypeDialogSubTitle:
    "It is classified into three major types.",
  BloodVesselHealthAgeDialogTitle:
    "Vascular Health\nreflects the function and condition of your blood vessels.",
  BloodVesselHealthAgeDialogSubTitle:
    "We'll explain it in an easy-to-understand way!",
  BloodVessleStandard: "Normal ",
  BloodVessleWarning: "Caution ",
  BloodVessleDanger: "Diagnostic required ",
  BloodVesslPulseValue: "Pulse Rate",
  BloodVesselResultHealthTitle: "My blood vessels are in good condition.",
  BloodVesselResultWaringTitle: "I need to take care of my vascular health.",
  BloodVesselResultDangerTitle: "I need a consultation with a professional.",
  BloodVesselResultSummaryHealth:
    "The blood vessel type is 'normal', with a waveform in the 2–30s, which is good. Keep yourself in your current state with the right lifestyle and regular exercise.",
  BloodVesselResultSummaryWaring:
    "The blood vessel type is 'caution', and it has a waveform in the 4–50s, so you need to be careful. Consider constant management and lifestyle improvements.",
  BloodVesselResultSummaryDanger:
    "The blood vessel type is 'diagnosis required' and has a waveform of 6 to 70s, so it needs to be managed. It is urgent to consult with experts and improve lifestyle.",

  // better
  BetterTitle: "It's better this way",
  food: "Food",
  exercise: "Exercise",
  better_title_food:
    "Magnesium, Omega-3 and sulfated substances\ndiet included in",
  better_title_exercise: "Persistent aerobic and flexible exercise",
  better_content_food:
    "Reduce saturated fat, trans fat, and sodium intake, and consume a variety of plant-based foods (fruits, vegetables, whole grains, nuts). Excessive alcohol consumption can also elevate blood pressure, so drink alcohol in moderation.",
  better_content_exercise:
    "For vascular health, engage in moderate to low–intensity aerobic exercise (walking, cycling) 3–5 times a week, each session lasting 30 minutes. Additionally, increase flexibility and improve blood circulation through stretching or yoga.",
};
